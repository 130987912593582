import React, { useEffect, useState } from 'react';

import { Descriptions, Badge, Typography, Card, DatePicker, Image, Modal, FloatButton, Form, Statistic, Col, Row, Button, Divider, Table, Select, Space, Alert } from 'antd';
import { RiseOutlined, FallOutlined, HomeOutlined, LogoutOutlined, SendOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import axios from 'axios';

import { Transfer } from './transfer';
import { Deposit } from './deposit';
import { Withdraw } from './withdraw';

import { Assets } from '../deals/assets';
import { Liabilities } from '../deals/liabities';

import { currencyAddresses, currencies, uxUpdateFrequency } from '../../utils/config';
import { zeroAddress } from '@notcentralised/notvault-sdk';

const { Title, Paragraph } = Typography;

const { Column } = Table;

const truncate = (str : string) => {
    if(str)
        return str.length > 20 ? str.substring(0, 10) + "..." + str.substring(str.length - 10, str.length) : str;
    else
        return str;
}

export const TokensUX: React.FC<any> = (parent) => {

    const [openMenu, setOpenMenu] = useState(false);//!parent.isMobile);

    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
    const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

    const [walletData, setWalletData] = useState<any>({});
    const [balanceData, setBalanceData] = useState<any>({});
    const [poolData, setPoolData] = useState<any>({});
    const [currency, setCurrency] = useState<string>("usdc");
    const [obligors, setObligors] = useState([zeroAddress]);
    const [obligor, setObligor] = useState(zeroAddress);

    const [isProcessingRecord, setProcessingRecord] = useState(false);
    const [activeRecord, setActiveRecord] = useState<any>({amount: null, decimals: 0});

    const { Option } = Select;

    const data = {
        // chainId: '31337',
        chainId: '153',
    }

    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    // const [tokens] = useState<Tokens>(new Tokens(data.vault));

    // eslint-disable-next-line
    // const { address, publicKey, contactId } = data.vault.getWalletData();
    // const address = '0x574D1135a10E91006eC937eFD2b29FC5B99F18a0';
    
    const onReceive = async (values: any) => {
        console.log('---onReceive', values)
        // let idHash = values.hash_id;
        // tokens.retreive(idHash, currencyAddresses[data.chainId][data.currency]);
    };

    let loading = false;
    useEffect(() => {
        // axios.get(
        //     `${azure_function_url}/LayerC?command=balance&chainid=${data.chainId}&currency=${currency}&obligor=${obligor}`, 
        //     {
        //         headers: {
        //           'Authorization': parent.bearerToken
        //         }
        //     }).then(x => { 
        //         const data = x.data.balances;
        //         setWalletData(x.data.wallet);

        //         const obligors = [zeroAddress];
        //         for(const x of data.lockedIn)
        //             obligors.push(x.obligor);

        //         for(const x of data.lockedOut)
        //             obligors.push(x.obligor);

        //         setObligors(Array.from(new Set(obligors)));

        //         setPoolData({ decimals: data.decimals, lockedIn: data.lockedIn, lockedOut: data.lockedOut, locked: data.lockedIn.concat(data.lockedOut).map((x: any, i: number) => { x.key=i; return x }) });

        //         setBalanceData({                         
        //             balance: BigInt(data.balance) / BigInt(data.decimals),
        //             privateBalance: BigInt(data.privateBalance) / BigInt(data.decimals), 
        //             lockedIn: BigInt(data.lockedIn.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? BigInt(currentValue.amount) : BigInt(0)), BigInt(0))) / BigInt(data.decimals), 
        //             lockedOut: BigInt(data.lockedOut.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? BigInt(currentValue.amount) : BigInt(0)), BigInt(0))) / BigInt(data.decimals)
        //         });
        //     });

        const id = setInterval(() => {

            if(!loading){
                axios.get(
                    `${azure_function_url}/LayerC?command=balance&chainid=${data.chainId}&currency=${currency}&obligor=${obligor}&type=all`, 
                    {
                        headers: {
                        'Authorization': parent.bearerToken
                        }
                    }).then(x => { 
                        const data = x.data.balances;

                        setWalletData(x.data.wallet);

                        const obligors = [zeroAddress];
                        for(const x of data.lockedIn)
                            obligors.push(x.obligor);

                        for(const x of data.lockedOut)
                            obligors.push(x.obligor);

                        setObligors(Array.from(new Set(obligors)));

                        setPoolData({ decimals: data.decimals, lockedIn: data.lockedIn, lockedOut: data.lockedOut, locked: data.lockedIn.concat(data.lockedOut).map((x: any, i: number) => { x.key=i; return x }) });

                        setBalanceData({                         
                            balance: BigInt(data.balance) / BigInt(data.decimals),
                            privateBalance: BigInt(data.privateBalance) / BigInt(data.decimals), 
                            lockedIn: BigInt(data.lockedIn.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? BigInt(currentValue.amount) : BigInt(0)), BigInt(0))) / BigInt(data.decimals), 
                            lockedOut: BigInt(data.lockedOut.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? BigInt(currentValue.amount) : BigInt(0)), BigInt(0))) / BigInt(data.decimals)
                        });

                        loading = false;
                    }).catch(err=> { loading = false });
            }
        
        }, uxUpdateFrequency); 

        // try{
        //     data.vault.setValue('A', 'B').then((txTest : any) => {
        //         console.log('---- TX TEST')
        //         console.log(txTest)
        //     })
        // }
        // catch{}

        return () => clearInterval(id); 
    // eslint-disable-next-line        
    }, [obligor]);
    
    return (
    <>
    <div style={{
        position: 'relative', 
        display: 'flex', 
        justifyContent: 'center', 
        height: 'calc(100vh - 65px)'
    }}>

        <div 
            className='custom-card' 
            style={{
                display: openMenu && parent.isMobile ? 'none' :  undefined,
                width:  '100%', 
                height: '100%',
                marginRight: parent.isMobile ? undefined :  '55px', 
                marginLeft: parent.isMobile || openMenu ? undefined:  '55px',
            }}
        >
            <Card 
                bordered={false} 
                className='custom-card'
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >

                {/* <Title level={2}>TradeFlows &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Layer C</Title> */}
                {/* <Divider plain></Divider> */}
                <Descriptions bordered>
                    <Descriptions.Item style={{textAlign:'left'}} label="Wallet Address" span={3}>
                        <Badge status="success" text={walletData.address} />
                    </Descriptions.Item>
                    <Descriptions.Item  style={{textAlign:'left'}}  label="Network" span={3}>
                        <Badge status="success" text={data.chainId === '5' ? 'Goerli' : data.chainId === '11155111' ? 'Sepolia' : data.chainId === '296' ?  'Hedera Testnet' : data.chainId === '153' ?  'Redbelly Testnet' : 'Localhost'} />
                    </Descriptions.Item>
                    <Descriptions.Item style={{textAlign:'left'}} label="Public Key" span={3}>
                        <Badge status="success" text={walletData.publicKey} />
                    </Descriptions.Item>
                </Descriptions>

                <br />
                <br /> 
                <br />

                <Row gutter={16}>
                    <Col span={12}>
                        <Select
                            size="large"
                            style={{ width: '100%' }}
                            defaultValue={'usdc'}
                            onChange={(val:string) => { setCurrency(val); }}
                            optionLabelProp="label"
                        >
                            {
                                Object.keys(currencies).map((currency, i) => 
                                    <Option value={currency} label={currencies[currency].label} key={i+1}>
                                        <Space>
                                            <span role="img" aria-label={currency}>
                                                <Image height={20} src={currencies[currency].url} ></Image>
                                            </span>
                                            {currencies[currency].label}
                                        </Space>
                                    </Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col span={12}>
                        <Select
                            size="large"
                            style={{ width: '100%' }}
                            defaultValue={zeroAddress}
                            onChange={(val:string) => { setObligor(val); }}
                            optionLabelProp="label"
                        >
                            {
                                obligors.map((_obligor, i) => 
                                    <Option value={_obligor} label={_obligor} key={i+1}>
                                        <Space>
                                            {_obligor}
                                        </Space>
                                    </Option>
                                )
                            }
                        </Select>
                    </Col>
                </Row>
                
                <br />
                <br />
                <br />
                

                <Row gutter={16}>
                    <Col span={6}>
                        <Statistic
                            prefix={<Image preview={false} height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                            title="Public Balance"
                            value={balanceData.balance ? balanceData.balance : 0}
                            precision={2}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic
                            prefix={<Image preview={false} height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                            title="Private Balance"
                            value={balanceData.privateBalance ? balanceData.privateBalance : 0}
                            precision={2}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic
                            prefix={<Image preview={false} height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                            title="Locked Liabilities"
                            value={balanceData.lockedOut ? balanceData.lockedOut : 0}
                            precision={2}
                        />
                    </Col>
                    <Col span={6}>
                        <Statistic
                            prefix={<Image preview={false} height={20} style={{marginTop: "-8px"}} src={currencies[currency].url} ></Image>}
                            title="Locked Assets"
                            value={balanceData.lockedIn ? balanceData.lockedIn : 0}
                            precision={2}
                        />
                    </Col>
                </Row>

                <br />
                <br />
                <br />

                <Row gutter={16}>
                    <Col span={12}>
                        <Assets isMobile={parent.isMobile} bearerToken={parent.bearerToken} user={parent.user} currency={currency} obligor={obligor} chainId={data.chainId} walletData={walletData} poolData={poolData} />
                    </Col>
                    <Col span={12}>
                        <Liabilities isMobile={parent.isMobile} bearerToken={parent.bearerToken} user={parent.user} currency={currency}  obligor={obligor} chainId={data.chainId} walletData={walletData} poolData={poolData} />
                    </Col>
                </Row>
                
                <br />
                <Divider orientation="left">Payments</Divider>
                { isProcessingRecord ?
                    <Alert
                        
                        message={`Please confirm the processing of transfer: ${( activeRecord.amount / poolData.decimals).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                        type="warning"
                        action={
                            <Space direction="vertical">
                                
                                <Button size="small" type="primary" danger onClick={async ()=> { 
                                    axios.post(
                                        `${azure_function_url}/LayerC?command=retreive_amount&chainid=${data.chainId}&currency=${currency}&obligor=${obligor}`, 
                                        {
                                            idhash: activeRecord.idHash
                                        },
                                        {
                                            headers: {
                                            'Authorization': parent.bearerToken
                                            }
                                        }).then(x => console.log(x));
                                }}>
                                    Process
                                </Button>
                                <Button size="small" danger type="dashed" onClick={() => setProcessingRecord(false) }>
                                    Cancel
                                </Button>
                            </Space>
                        }
                    />
                :
                    <></>
                }
                <Form
                    name="receive_amount"
                    onFinish={onReceive}
                    initialValues={{}}
                    labelCol={{ span: 8 }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Table 
                                dataSource={poolData.locked ? poolData.locked.filter((x : any) => x.active) : []}
                                pagination={{hideOnSinglePage: true}}
                            >
                                <Column
                                    title="Type"
                                    key="type"
                                    render={(_: any, record: any) => (
                                        record.sender.toLowerCase() !== walletData.address.toLowerCase() ? <RiseOutlined twoToneColor="#52c41a" /> : <FallOutlined twoToneColor="#eb2f96" />
                                    )}
                                />
                                <Column
                                    title="Counterpart"
                                    key="couterpart"
                                    render={(_: any, record: any) => (
                                        record.sender.toLowerCase() === walletData.address.toLowerCase() ? truncate(record.owner) : truncate(record.sender)
                                    )}
                                />
                                {/* <Column
                                    title="Obligor"
                                    key="obligor"
                                    render={(_: any, record: any) => (
                                        truncate(record.obligor)
                                    )}
                                /> */}
                                <Column
                                    title="Amount"
                                    key="amount"
                                    render={(_: any, record: any) => (
                                        <><Image preview={false} height={15} style={{marginTop: "-2px"}} src={currencies[currency].url} /> {(record.amount / poolData.decimals).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
                                    )}
                                />
                                <Column
                                    title="Created"
                                    key="created"
                                    render={(_: any, record: any) => (
                                        <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.created)} />
                                    )}
                                />
                                <Column
                                    title="Unlock"
                                    key="unlock"
                                    render={(_: any, record: any) => (
                                        <DatePicker disabled showTime format="YYYY-MM-DD HH:mm:ss" value={dayjs.unix(record.sender.toLowerCase() === walletData.address.toLowerCase() ? record.unlock_sender : record.unlock_receiver)} />
                                    )}
                                />
                                <Column
                                    title="Action"
                                    key="action"
                                    render={(_: any, record: any) => (
                                        <Button 
                                        type="default" 
                                        block
                                        disabled={!record.active || (record.owner.toLowerCase() === walletData.address.toLowerCase() ? record.unlock_receiver * 1000 > Date.now() : record.unlock_sender * 1000 > Date.now())}                                
                                        onClick={() => { setActiveRecord(record); setProcessingRecord(true); }}
                                        >
                                            {record.owner.toLowerCase() === walletData.address.toLowerCase() ? 'Redeem': 'Cancel' }
                                        </Button>
                                    )}
                                />
                            </Table>
                        </Col>
                    </Row>
                </Form>

            </Card>
                    
        </div>
        
    </div>
    <FloatButton.Group
            type="default"
            style={{ right: 50 }}
            icon={<HomeOutlined />}
        >
            <FloatButton tooltip={<div>Private Transfer</div>} icon={<SendOutlined />} onClick={()=> {setIsTransferModalOpen(true);}} />
            <FloatButton tooltip={<div>Deposit</div>} icon={<PlusCircleOutlined />} onClick={()=> {setIsDepositModalOpen(true);}} />
            <FloatButton tooltip={<div>Withdraw</div>} icon={<MinusCircleOutlined />} onClick={()=> {setIsWithdrawModalOpen(true);}} />
            <FloatButton tooltip={<div>Logout</div>} icon={<LogoutOutlined />} onClick={async () => {
                // const permissions = await ethereum.request({
                //   method: 'wallet_requestPermissions',
                //   params: [{
                //     eth_accounts: {},
                //   }]
                // });
                // setWalletData({ address: null });
                // setCurrent(0);

                parent.logout();
            }}/>
        </FloatButton.Group>
    
        <Modal title="Transfer" closable={false} open={isTransferModalOpen} footer={null}>
            <Transfer 
                bearerToken={parent.bearerToken}
                user={parent.user}
                walletData={walletData}
                balanceData={balanceData}
                chainId={data.chainId}
                obligor={obligor}
                currency={currency}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsTransferModalOpen(false);}}
            />
        </Modal>

        <Modal title="Deposit" closable={false} open={isDepositModalOpen} footer={null}>
            <Deposit 
                balanceData={balanceData}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsDepositModalOpen(false);}}
            />
        </Modal>

        <Modal title="Withdraw" closable={false} open={isWithdrawModalOpen} footer={null}>
            <Withdraw 
                balanceData={balanceData}
                tokenAddress={currencyAddresses[data.chainId][currency]}
                closeModal={() => {setIsWithdrawModalOpen(false);}}
            />
        </Modal>
    </>
    );
}