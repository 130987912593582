import React, { useState, useEffect } from 'react';
import { List, Avatar, Skeleton, Result, Modal, message, Form, Input, Checkbox, Typography, Space, Spin, Menu, Statistic, Layout, Button, theme, Card, Col, Row, Image } from 'antd';
import { CheckOutlined, UserOutlined, TeamOutlined, UndoOutlined, HomeOutlined, FileProtectOutlined, InboxOutlined, SendOutlined, OrderedListOutlined, WalletOutlined, ShoppingCartOutlined, LogoutOutlined, SafetyCertificateOutlined, SnippetsOutlined, FormOutlined } from '@ant-design/icons';

import axios from 'axios';

import type { MenuProps } from 'antd';

import { Inbox } from '../main/Inbox';
import { InvitationStatus, EmployeeStatus } from '../../utils/constants';

import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../../utils/auth/authConfig";
import { msalInstance } from "../../index";


const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export const Subscribe = (parent: any) => {

    const { instance } = useMsal();

    // const [bearerToken, setBearerToken] = useState('');
    const [products, setProducts] = useState<any[]>([]);
    const [messageCounter, setMessageCounter] = useState<number>(0);

    const [warningMessage, setWarningMessage] = useState<boolean>(false);

    const [form] = Form.useForm();

    const azure_function_url = process.env.AZURE_FUNCTION_URL;    

    useEffect(() => {
        axios.get(
            `${azure_function_url}/LLMModel?command=payments_is_subscribed`,
            {
                maxContentLength: Number.POSITIVE_INFINITY,
                headers: {
                    'Authorization': parent.bearerToken
                }
            }
        ).then(x => {
            
            if(x.data?.toLowerCase() === 'true'){
                // Should never get here
                message.error('Should not happen');
            }
            else {
                axios.get(
                    `${azure_function_url}/LLMModel?command=payments_subcriptions`,
                    {
                        maxContentLength: Number.POSITIVE_INFINITY,
                        headers: {
                            'Authorization': parent.bearerToken
                        }
                    }
                ).then(x => {
                    if(x.data.length == 0) {
                        axios.get(
                            `${azure_function_url}/LLMModel?command=payments_products`,
                            {
                                maxContentLength: Number.POSITIVE_INFINITY,
                                headers: {
                                    'Authorization': parent.bearerToken
                                }
                            }
                        ).then(x => {
                            setProducts(x.data.filter((x:any)=>x.active));
                        }).catch(err => {console.log(err);message.error(err.toString()); });
                    }
        
                }).catch(err => {console.log(err); });
            }
        });

        
    }, []);

    const handlePurchase = (productId: string) => {
        const url = `${azure_function_url}/LLMModel?command=payments_subscribe&id=${productId}`;
        const redirectUrl = window.location.origin + "?update_subscriptions=True";

        axios.post(url, { redirect_url: redirectUrl }, {
            maxContentLength: Number.POSITIVE_INFINITY,
            headers: { 'Authorization': parent.bearerToken }
        }).then(response => {
            window.location.replace(response.data.url);
        }).catch(error => {
            console.error('Purchase error:', error);
            // Handle the error appropriately
        });
    };


    return (
        <>
        <Layout style={{ padding: '24px' }}>
            <Content style={{ margin: '24px 16px', padding: 24, background: '#fff', minHeight: 280 }}>
                <Row gutter={16}>
                    <Col xs={24} sm={12} md={8} lg={12}>
                        <Card 
                            title="Register New Payment Account"
                            extra={
                                <Space>
                                    <Button style={{float:'right'}} icon={<UndoOutlined/>} onClick={async () => { await msalInstance.acquireTokenRedirect({...loginRequest}); }  }/>
                                    <Button icon={<LogoutOutlined />} onClick={() => instance.logoutRedirect()} />
                                </Space>
                            }
                        >
                            <Form 
                                {...{
                                    labelCol: { span: 8 },
                                    wrapperCol: { span: 16 },
                                }}

                                form={form} 

                                initialValues={{
                                    name: parent.user?.entity?.name,
                                    reference: parent.user?.entity?.reference,
                                    address: ''
                                }}

                                disabled={parent.user?.entity?.name}

                                onFinish={(values) => {
                                    axios.post(
                                        `${azure_function_url}/NCID?command=entity_create`,
                                        // values, 
                                        {
                                            name: values.name,
                                            reference: '',//values.reference,
                                            address: values.address
                                        },
                                        {
                                            headers: { 'Authorization': parent.bearerToken }
                                        }
                                    ).then(response => {
                                        // setWarningMessage(true);
                                        // setTimeout(() => {msalInstance.logoutRedirect()}, 3 * 1000);
                                        window.location.reload();

                                    }).catch(error => {
                                        console.error('Purchase error:', error);
                                        // Handle the error appropriately
                                    });
                                    // Handle the form submission
                                }}
                            >
                                <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the company name!' }]}>
                                    <Input placeholder="Company Name" />
                                </Form.Item>
                                {/* <Form.Item label="Reference Number" name="reference" rules={[{ required: true, message: 'Please input the reference number!' }]}>
                                    <Input placeholder="Reference Number" />
                                </Form.Item> */}
                                <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please input the address!' }]}>
                                    <Input placeholder="Address" />
                                </Form.Item>
                                <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
                                    <Button type="primary" htmlType="submit">
                                        Register
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={16} lg={12}>
                        {
                            parent.user?.entity?.permission === EmployeeStatus.OWNER ?
                            <Card 
                                bordered={true}
                                title="Please choose a subscription..."
                            >
                                <Row gutter={16} >
                                    <List
                                        style={{width:'100%'}}
                                        loading={!(products?.length > 0)}
                                        itemLayout="horizontal"
                                        dataSource={products.sort((a, b) => a.prices[0].amount - b.prices[0].amount)}
                                        renderItem={(item:any) => (
                                            <List.Item
                                                actions={[<Button
                                                    type="primary"
                                                    style={{ 
                                                        borderRadius: '30px', // Make the button edges round
                                                        padding: '0 20px'
                                                    }}
                                                    onClick={() => handlePurchase(item.prices[0].id)}
                                                    block
                                                >
                                                    Subscribe
                                                </Button>]}
                                            >
                                                <img
                                                    style={{borderRadius:'30px', marginRight:'50px'}}
                                                    width={90}
                                                    alt="logo"
                                                    src={item.images[0]}
                                                />
                                                <List.Item.Meta
                                                    title={item.name}
                                                    description={item.description}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Row>
                            </Card>
                            :
                            <>
                                <Inbox bearerToken={parent.bearerToken} user={parent.user} reload={() => {console.log('reload')}} counterCallback={(counter) => setMessageCounter(counter)}/>
                                {messageCounter === 0 ?
                                    <div style={{
                                        padding: '30px'
                                    }}>
                                        <Typography>
                                            <Paragraph>
                                                You have no invitations to join an account. Either wait for such an invitation or register your own payment account linked to a credit card.
                                            </Paragraph>

                                            <Paragraph>
                                                If you are registering a Payment Account, upon completion, the system will log you out for enhanced security. Please log back in after this.
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                    :
                                    <></>
                                }
                                
                            </>
                        }
                    </Col>
                </Row>
            </Content>
        </Layout>

        <Modal okType={'default'} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }} closable={false} open={warningMessage}>
                <Card bordered={true} style={{ width: '100%' }}>
                    <Result
                        status="warning"
                        title="You will no be logged out for enhanced security reasons, please log back on."
                    />
                </Card>
            </Modal>
        </>
    )
}