import React, { useState } from 'react';
import { Form, InputNumber, Col, Row, Button, Divider, Typography, Alert } from 'antd';

import { Tokens } from '@notcentralised/notvault-sdk';

const { Paragraph } = Typography;

export const Withdraw: React.FC<any> = (data) => {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [tokens] = useState<Tokens>(new Tokens(data.vault));

    const onSend = async (values: any) => {    
        setLoading(true);

        // eslint-disable-next-line
        const { balance, decimals} = await tokens.tokenBalance(data.tokenAddress);


        let amount = BigInt(values.amount) * decimals;

        // await tokens.withdraw(data.tokenAddress, amount);
        form.resetFields();
        data.closeModal();
        setLoading(false);
    };

    return (
        <>
            <Form
                name="withdraw"
                form={form}
                onFinish={onSend}
                initialValues={{}}
            >
                <Divider orientation="left"></Divider>
                <Paragraph style={{textAlign:'left'}}>
                You will transfer tokens from your vault to your liquid balance.
                </Paragraph>
                <br></br>
                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item 
                        label="Amount" 
                        name="amount"
                        rules={[{ required: true, message: 'Please input request' }]}
                    >
                        <InputNumber
                            min={0} 
                            style={{ width: '100%' }} 

                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />
                    </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left"></Divider>
                {
                    data.balanceData.privateBalance < form.getFieldValue('amount') ? 
                    <>
                        <Alert
                            
                            message="The balance is not enough to commit the withdrawal amount..."
                            type="error"
                        />
                        <br></br>
                    </>
                    :
                    <></>
                }
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => { form.resetFields(); setLoading(false); data.closeModal(); }}
                        disabled={false}
                    >
                        Cancel
                    </Button>

                    <Button 
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={data.balanceData.privateBalance < form.getFieldValue('amount')}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Withdraw
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}