import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Col, Row, Button, Divider, Alert } from 'antd';

// import { Tokens } from '@notcentralised/notvault-sdk';

import axios from 'axios';

export const Transfer: React.FC<any> = (parent) => {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);

    // const [tokens] = useState<Tokens>(new Tokens(data.vault));
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const [balanceData, setBalanceData] = useState<any>({});
    const [poolData, setPoolData] = useState<any>({});
    const [currency, setCurrency] = useState<string>("usdc");

    useEffect(() => {
        axios.get(
            `${azure_function_url}/LayerC?command=balance&chainid=${parent.chainId}&currency=${parent.currency}&obligor=${parent.obligor}`, 
            {
                headers: {
                  'Authorization': parent.bearerToken
                }
            }).then(x => { 
                const data = x.data.balances;

                setPoolData({ decimals: data.decimals, lockedIn: data.lockedIn, lockedOut: data.lockedOut, locked: data.lockedIn.concat(data.lockedOut).map((x: any, i: number) => { x.key=i; return x }) });

                setBalanceData({                         
                    balance: BigInt(data.balance) / BigInt(data.decimals),
                    privateBalance: BigInt(data.privateBalance) / BigInt(data.decimals), 
                    lockedIn: BigInt(data.lockedIn.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? currentValue.amount : BigInt(0)), BigInt(0))) / BigInt(data.decimals), 
                    lockedOut: BigInt(data.lockedOut.reduce((accumulator:any, currentValue:any) => accumulator + (currentValue.active ? currentValue.amount : BigInt(0)), BigInt(0))) / BigInt(data.decimals)
                });
            });
    },[]);

    const onSend = async (values: any) => {    
        
        setLoading(true);
        
        axios.post(
            `${azure_function_url}/LayerC?command=send_amount&chainid=${parent.chainId}&currency=${parent.currency}&obligor=${parent.obligor}`, 
            {
                destination: values.destination_address,
                amount: values.destination_amount * poolData.decimals,
            },
            {
                headers: {
                  'Authorization': parent.bearerToken
                }
            }).then((x:any) => {
                form.resetFields();
            parent.closeModal();
            setLoading(false);
            });

        
    };

    return (
        <>
            <Form
                name="send_amount"
                form={form}
                onFinish={onSend}
                initialValues={{}}
                labelCol={{ span: 8 }}
            >
                <Divider orientation="left"></Divider>
                <br></br>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            style={{width:'100%'}}
                            name="destination_address"
                            label="Destination Address"
                            rules={[{ required: true, message: 'Please pick a term' }]}
                            >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item 
                            label="Amount" 
                            name="destination_amount"
                            rules={[{ required: true, message: 'Please input request' }]}
                        >
                            <InputNumber
                                min={0} 
                                style={{ width: '100%' }} 

                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left"></Divider>
                {
                    parent.balanceData.privateBalance < form.getFieldValue('destination_amount') ? 
                    <>
                        <Alert
                            
                            message="The private balance is not enough to commit the initial amount..."
                            type="error"
                        />
                        <br></br>
                    </>
                    :
                    <></>
                }
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => { form.resetFields(); setLoading(false); parent.closeModal(); }}
                        disabled={false}
                    >
                        Cancel
                    </Button>

                    <Button 
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        disabled={parent.balanceData.privateBalance < form.getFieldValue('destination_amount')}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Send
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}