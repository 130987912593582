// App.tsx

import React, { useState, useRef, useEffect, useReducer } from 'react';

import { Tour, FormInstance, Select, Popconfirm, TreeSelect, Divider, Modal, Card, FloatButton, Button, List, Form, Space, message } from 'antd';
import type { TourProps } from 'antd';
import { ApartmentOutlined, LogoutOutlined, PlusCircleOutlined, MinusCircleOutlined, InfoCircleOutlined, DownOutlined, UpOutlined, SendOutlined, LoadingOutlined, DeleteOutlined} from '@ant-design/icons';

import hljs from 'highlight.js';
import { marked } from 'marked';
import 'highlight.js/styles/github.css';  // Or any other style you prefer

import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

import axios from 'axios';

import { getPrompt, prompts, gptModel } from '../../utils/prompts';

import { ShowOutput } from './ShowOutput';
import { Idea } from './Idea';

const { Item } = Form;

const { Option } = Select;

const renderMarkdown = (_markdownContent: any) => {

    let markdownContent = '';
    if(Array.isArray(_markdownContent))
        markdownContent = _markdownContent.join('\n');
    else
        markdownContent = _markdownContent;
    
    if(markdownContent.startsWith('```markdown'))
        markdownContent = markdownContent.substring('```markdown'.length);

    if(markdownContent.endsWith('```'))
        markdownContent = markdownContent.substring(0, markdownContent.length - '```'.length);
    
    // Step 1: Convert markdown to HTML
    const htmlContent = marked(markdownContent.toString());

    // Step 2: Parse and highlight code blocks
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Find all <pre> elements containing <code> (i.e., code blocks)
    const codeBlockElements = doc.querySelectorAll('pre > code');

    codeBlockElements.forEach(codeElem => {
        const rawCode = codeElem.textContent || '';
        const highlighted = hljs.highlightAuto(rawCode).value;
        codeElem.innerHTML = highlighted;

        // Wrap the code block inside a div with a 'code-card' class
        const wrapper = doc.createElement('div');
        wrapper.className = 'code-card';
        const parentPre = codeElem.parentElement;
        if (parentPre) {
            parentPre.parentNode?.replaceChild(wrapper, parentPre);
            wrapper.appendChild(parentPre);
        }
    });

    // Step 3: Return the updated HTML
    return doc.body.innerHTML;
};

interface Message {
    id: string;
    author: string;
    timestamp: Date;
    runningTime?: number;
    input: string | string[];
    content: string | string[];
    data: any;
    show: boolean;
    prompt_tokens?: number;
    completion_tokens?: number;
    docsearch?: string;
    tags?: any;
    agent_reference: string;
    model: string;
    temperature: number;
    signature?: any;
    prompts?: any[];
    public?: Date | undefined;
}

const uniqueObjects = (objects: any[]) => objects?.reduce((unique, obj) => {
    if (!unique.some((o:any) => o.name === obj.name)) {
      unique.push(obj);
    }
    return unique;
}, [] as any[]);

const tryParseJson = (jsonString?: string): any => {
    try {
        if(!jsonString) return jsonString;
        
        const res = JSON.parse(jsonString);

        return res;
    } catch (e) {
          return jsonString;
    }
};
  
const MessageTree : Record<string,  Message[]> = {};

export const SIKE: React.FC<any> = (parent) => {

    const [input, setInput] = useState('');
    const [tags, setTags] = useState();
    const [agent, setAgent] = useState(parent.agent || 'chat');
    const [tagTree, setTagTree] = useState<any>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [openMessage, setOpenMessages] = useState<Message>();

    const [lastMessage, setLastMessage] = useState<string>();

    const formRef = useRef<FormInstance>(null);

    const [isLoadingChat, setLoadingChat] = useState({ state: false });

    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const messagesTopRef = useRef<HTMLDivElement | null>(null);
    const messageInputRef = useRef<HTMLDivElement | null>(null);
    const listContainerRef = useRef<HTMLDivElement | null>(null);

    const [open, setOpen] = useState<boolean>(false);
 
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        
        setTimeout(() => {
            messageInputRef.current?.focus();    
        }, 500);

        const element = listContainerRef.current;
        if (element) {
            setTimeout(() => {
                element.scrollTop = element.scrollHeight;
                
            }, 0);
        }
    };

    const scrollToTop = () => {
        messagesTopRef.current?.scrollIntoView({ behavior: "smooth" });  
    };

    const getAllTextAreaValues = () => {
        // Retrieve all form values
        const allValues = formRef.current?.getFieldsValue();
    
        // Extract the prompts array which contains the values of the TextAreas
        const promptsArray = allValues.prompts;
    
        // Check if promptsArray is valid and has items
        if (Array.isArray(promptsArray) && promptsArray.length > 0) {
            // Map through the array and extract the 'prompt' values
            const textAreaValues = promptsArray.map(item => item.prompt);
            return textAreaValues;
        } else {
            // Return an empty array if no valid data is found
            return [];
        }
    };

    const clearAllTextAreas = (text?: string) => {
        formRef.current?.setFieldsValue({ prompts: [{ prompt: text ?? ' '}] });
    };
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;
  
    useEffect(() => {
        if(!MessageTree[parent.context])
            MessageTree[parent.context] = [];

        setMessages(MessageTree[parent.context]);
        updateHistory();

        axios.get(
            `${azure_function_url}/LLMModel?command=tags&context=${parent.context}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                if (res.data !== null && res.data !== undefined && res.data?.length > 0)
                    setTagTree(res.data)
        }).catch(err => {console.log(err);message.error(err.toString())});

    }, [parent.context, parent.thread, parent.updateCounter]);

    useEffect(() => {
        setLastMessage(undefined);        
        const timer = setTimeout(scrollToBottom, 100);
        return () => clearTimeout(timer);
    }, [messages]);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldsValue({ agent: agent });
        }
    }, [agent]);

    useEffect(() => {
        if(parent.agent) 
            setAgent(parent.agent)
    }, [parent.agent]);

    const sendPrompt = async (_input: string | string[]) => {
        const input = _input || getAllTextAreaValues();
        
        if(input[0] === '')
            return

        if (input) {
            MessageTree[parent.context] = [
                ...messages, 
                { id: '', author: 'User', content: input, input: input, data: undefined, timestamp: new Date(), show: false, agent_reference: '', model: '', temperature: 0 },
                { id: '', author: 'Skynet', content: '', data: undefined, input: input, timestamp: new Date(), show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined, prompt_tokens: 0, completion_tokens: 0 }
            ];
            setLoadingChat({ state: true});

            setInput((input) => input.length > 150 ? input.substring(0, 150) : input);
            
            setMessages(MessageTree[parent.context]);
            
            for(var i = 0; i < 1; i++){
                try{
                    setInput('');   
                    clearAllTextAreas('working my magic...');
                    
                    await axios.post(
                        `${azure_function_url}/LLMModel?command=flow`, 
                        parent.prompt ? 
                            {...parent.prompt(input[0]),async: 'true'} : 
                            {...getPrompt(parent.context, parent.thread, agent, input[0], tags),async: 'true'},
                        {
                            headers: {
                                'Authorization': parent.bearerToken
                            }
                        }
                    ).then((res:any) => {

                        const waitOneSecond = (): Promise<void> => {
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                }, 1000); // 1000 milliseconds = 1 second
                            });
                        }

                        const _id = res.data;

                        const date = new Date();
                        MessageTree[parent.context] = [
                            ...messages, 
                            { id: '', author: 'User', content: input, data: undefined, timestamp: date, input: input, show: false, agent_reference: agent, model: gptModel, temperature: 0 }, 
                            { id: '', author: 'Skynet', content: '', data: undefined, input: input, timestamp: date, show: false, tags: undefined, docsearch: undefined, agent_reference: agent, model: gptModel, temperature: 0, signature: undefined }];
        
                        setMessages(MessageTree[parent.context]);
                        

                        const timer = setInterval(() => {
                            axios.get(`${azure_function_url}/LLMModel?command=flow_stream&id=${_id}&pid=first`,
                            {
                                headers: {
                                'Authorization': parent.bearerToken
                                }
                            }
                            )
                            .then((data:any) => {
                                if(data.data.finished){
                                    clearInterval(timer);

                                    waitOneSecond()
                                    .then(() => {
                                        updateHistory();
                                        clearAllTextAreas();
                                    });
                                }
                                else 
                                // if(data.data.content.trim() !== '')
                                {
                                    setLastMessage(data.data.content + '...');
                                    scrollToBottom();
                                }
                            });
                        }, 1000);

                    }).catch(err => {
                        console.log(err);
                        message.error(err.toString());

                        const date = new Date();
                        MessageTree[parent.context] = [
                            ...messages, 
                            { id: '', author: 'User', content: input, data: undefined, timestamp: date, input: input, show: false, agent_reference: '', model: '', temperature: 0 }, 
                            { id: '', author: 'Skynet', content: err.toString(), data: undefined, input: input, timestamp: date, show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined }];
        
                        setMessages(MessageTree[parent.context]);
                        setLoadingChat({ state: false});
                        forceUpdate();
                    });
        
                    setInput('');   
                    clearAllTextAreas();
                    return;
                }
                catch(err){
                    console.log('---err---', err)
                }
                message.warning(`resending your message. (${i+1}/5)`);
                await new Promise(f => setTimeout(f, 1000));
                setLoadingChat({ state: false});
            }
        }
    };

    const handleMessageSend = async () => {
        sendPrompt(input);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
        // const textarea = event.currentTarget;

        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // prevent creating a new line
            handleMessageSend();
        }

        // else if (event.key === 'Tab') {
        //     event.preventDefault();

        //     const spaces = "    "; // 4 spaces for a tab, you can adjust this
        //     const start = textarea.selectionStart;
        //     const end = textarea.selectionEnd;
        //     const selectedText = textarea.value.substring(start, end);

        //     if (start !== end) {
        //         // Text is selected. Indent each line of the selected text.
        //         const indentedText = selectedText.split('\n').map(line => spaces + line).join('\n');
        //         textarea.value = textarea.value.substring(0, start)
        //             + indentedText
        //             + textarea.value.substring(end);
                
        //         // Adjust the selection to highlight the newly indented text
        //         textarea.selectionStart = start;
        //         textarea.selectionEnd = start + indentedText.length;
        //     } else {
        //         // No text selected. Insert spaces for a tab.
        //         textarea.value = textarea.value.substring(0, start)
        //             + spaces
        //             + textarea.value.substring(end);

        //         // Position the caret after the inserted spaces
        //         textarea.selectionStart = textarea.selectionEnd = start + spaces.length;
        //     }
        // }
    };

    const ref_input = useRef(null);
    const ref_agent = useRef(null);
    const ref_tags = useRef(null);
    const ref_send = useRef(null);
    const ref_messages = useRef(null);
    const ref_microsig = useRef(null);
    const ref_publish = useRef(null);
    const ref_sources = useRef(null);
    const ref_redo = useRef(null);
    const ref_copy = useRef(null);
    
    const steps: TourProps['steps'] = [
        {
          title: 'Input Prompt',
          description: 'Type your prompt with a question or statement.',
        //   cover: (
        //     <img
        //       alt="tour.png"
        //       src="https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png"
        //     />
        //   ),
          target: () => ref_input.current,
        },
        {
          title: 'Query Model',
          description: `Select 'Documents' to query specific files, 'GPT4 Turbo' to query ChatGPT, or 'Hybrid' to combine the approaches.`,
          target: () => ref_agent.current,
        },
        {
            title: 'Tags',
            description: 'Click the arrow to filter your Query Model',
            target: () => ref_tags.current,
        },
        {
          title: 'Send',
          description: 'Click the arrow to submit your query to your chosen Query Model.',
          target: () => ref_send.current,
        },
        {
          title: 'Query Output',
          description: 'Displays the output from your query, based on the selected model.',
          target: () => ref_messages.current,
        },
        {
          title: 'MicroSignatures',
          description: `Click to 'sign' the query output and attest to its validity.`,
          target: () => ref_microsig.current,
        },
        {
            title: 'Publish',
            description: 'Click to make this output public',
            target: () => ref_publish.current,
        },
        {
          title: 'Sources',
          description: 'Click to inspect the underlying sources used by the model to answer the query',
          target: () => ref_sources.current,
        },
        {
          title: 'To Prompt',
          description: 'Click to copy and submit the query output, as a new query itself',
          target: () => ref_redo.current,
        },
        {
          title: 'Copy',
          description: 'Click to copy the query output text, for pasting in other applications',
          target: () => ref_copy.current,
        }
    ];

    const updateHistory = () => {
        axios.get(
            `${azure_function_url}/LLMModel?command=history&context=${parent.context}&thread=${parent.thread}`, 
            {
                headers: {
                'Authorization': parent.bearerToken
                }
            }).then(res => {
                let mess: Message[] = []
                if(res.data && res.data.length > 0)
                    res.data.forEach((entry: any) => {
                        const data =  JSON.parse(entry.response);
                        mess.push({ 
                            id: entry.id,
                            author: 'User', 
                            input: tryParseJson(entry.input),
                            content: tryParseJson(entry.input),
                            timestamp: new Date(entry.prompt_timestamp),
                            data: undefined,
                            show: false,
                            agent_reference: entry.agent_reference,
                            model: entry.model,
                            temperature: entry.temperature,
                            prompts: entry.prompts
                        }, { 
                            id: entry.id,
                            author: 'Skynet', 
                            content: data.text ? data.text : 'error',
                            timestamp: new Date(entry.response_timestamp),
                            runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                            data: data,//'error',
                            input: tryParseJson(entry.input),
                            tags: entry.metadata,
                            docsearch: entry.docsearch,
                            show: false,
                            agent_reference: entry.agent_reference,
                            model: entry.model,
                            temperature: entry.temperature,
                            prompt_tokens: entry.prompt_tokens,
                            completion_tokens: entry.completion_tokens,
                            signature: entry.signature,
                            prompts: entry.prompts,
                            public: entry.public
                        });
                    });
                else if(parent.thread !== '-') {
                    let initMessage = parent.initMessage || `Hello ${parent.user.data.personal.name.firstName}, how can I help you?`;
                    mess.push(
                        { id: '', author: 'Skynet', content: initMessage, data: undefined, input: input, timestamp: new Date(), show: false, tags: undefined, docsearch: undefined, agent_reference: '', model: '', temperature: 0, signature: undefined }
                    );

                    const step = 5;
                    let prevText = ''
                    let index = 0;
                    const timer = setInterval(() => {
                        if (index < initMessage.length) {

                            prevText = initMessage.substring(0, index + step);
    
                            setLastMessage(prevText);
                            index += step;
                        } else {
                            clearInterval(timer);
                            scrollToBottom();

                            if(messages.length > 0)
                                setLastMessage(undefined);
                        }
                    }, 5);
                }
                
                setMessages(mess);
                setLoadingChat({ state: false});
                forceUpdate();
                
        }).catch(err => {console.log(err);message.error(err.toString())});
    }
  
    return (
        <>
            <div ref={listContainerRef} 
                style={{ 
                    position: 'relative', 
                    height:'100%', 
                    width: '100%' 
                }}
            >
                <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
                <List
                    style={{ 
                        width:'100%',
                        height: 'calc(100% - 60px)',
                        overflow: 'auto',
                        position: 'absolute',

                        flexGrow: 1
                    }}
                    itemLayout="vertical"
                    loading={isLoadingChat.state}
                    dataSource={messages.map((x,i)=>{return {...x, key:i}})}
                    renderItem={(_message, i) => (
                        <>
                            <br></br>
                            <List.Item 
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row', // Horizontal layout
                                    borderBottom: i === messages.length - 1 ? 'none' : '1px solid black',
                                    alignItems: 'flex-start' // Align items at the start of the flex container
                                }}
                            >
                                <ShowOutput 
                                    isMobile={parent.isMobile}
                                    input={_message.input}
                                    content={(i === messages.length - 1) && lastMessage ? lastMessage : _message.content}
                                    prompts={_message.prompts}
                                    author={_message.author}
                                    agent_reference={_message.agent_reference}
                                    prompt_tokens={_message.prompt_tokens ?? 0}
                                    completion_tokens={_message.completion_tokens ?? 0}
                                    runningTime={_message.runningTime}
                                    timestamp={_message.timestamp}
                                    isSigned={_message.signature}
                                    isPublic={_message.public !== undefined && _message.public !== null}
                                    sourceDocuments={_message.data?.sourceDocuments}
                                    tags={_message.data?.tags}
                                    docsearch={_message.docsearch}
                                    label={parent.label}
                                    i={i}
                                    totalMessages={messages.length - 1}
                                    user={parent.user}
                                    delete={()=>{ 
                                        axios.get(
                                            `${azure_function_url}/LLMModel?command=history_delete&context=${parent.context}&thread=${parent.thread}&id=${_message.id}`, 
                                            {
                                                headers: {
                                                'Authorization': parent.bearerToken
                                                }
                                        }).then(res => {
                                            let mess: Message[] = []
                                            if(res.data.forEach)
                                                res.data.forEach((entry: any) => {
                                                    const data =  JSON.parse(entry.response);
                                                    mess.push({ 
                                                        id: entry.id,
                                                        author: 'User', 
                                                        input: tryParseJson(entry.input),
                                                        content: tryParseJson(entry.input),
                                                        timestamp: new Date(entry.prompt_timestamp),
                                                        data: undefined,
                                                        show: false,
                                                        agent_reference: entry.agent_reference,
                                                        model: entry.model,
                                                        temperature: entry.temperature,
                                                        prompts: entry.prompts
                                                    }, { 
                                                        id: entry.id,
                                                        author: 'Skynet', 
                                                        content: data.text ? data.text : 'error',
                                                        timestamp: new Date(entry.response_timestamp),
                                                        runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                        data: data,//'error',
                                                        input: tryParseJson(entry.input),
                                                        tags: entry.metadata,
                                                        docsearch: entry.docsearch,
                                                        show: false,
                                                        agent_reference: entry.agent_reference,
                                                        model: entry.model,
                                                        temperature: entry.temperature,
                                                        prompt_tokens: entry.prompt_tokens,
                                                        completion_tokens: entry.completion_tokens,
                                                        signature: entry.signature,
                                                        prompts: entry.prompts,
                                                        public: entry.public
                                                    });
                                                })
                                            
                                            message.success('Message deleted');
                                            setMessages(mess);
                                        }).catch(err => {console.log(err);message.error(err.toString())});
                                     }}
                                    movePrompt={()=>{ 
                                        setAgent(_message.agent_reference);
                                        formRef.current?.setFieldsValue({ prompts: [ { prompt: typeof _message.content === 'object' ? JSON.stringify(_message.content).toString().trim() : _message.content.toString().trim() } ] });
                                        messageInputRef.current?.focus();
                                        forceUpdate();
                                    }}
                                    microSign={()=>{ 
                                        axios.post(
                                            `${azure_function_url}/NCID?command=signature_request`,
                                            {
                                                document: {
                                                    id: _message.id,
                                                    version: 1,
                                                    type: 'sike_microsignature',

                                                    model: _message.model,
                                                    tags: _message.tags,
                                                    temperature: _message.temperature,
                                                    agent_reference: _message.agent_reference,
                                                    sources:  _message.data ? uniqueObjects(_message.data.sourceDocuments.map((x:any)=> { return {name: x?.metadata?.name, type: x?.metadata?.type, id: x?.metadata?.id }}).flat()) : undefined
                                                },
                                            },
                                            {
                                                maxContentLength: Number.POSITIVE_INFINITY,
                                                headers: {
                                                    'Authorization': parent.bearerToken
                                                }
                                            }
                                        ).then(x => { 
                                            
                                            axios.post(
                                                `${azure_function_url}/LLMModel?command=add_signature&context=${parent.context}&thread=${parent.thread}&id=${_message.id}`,
                                                {
                                                    signature: x.data
                                                },
                                                {
                                                    headers: {
                                                        'Authorization': parent.bearerToken
                                                    },
                                                    
                                                }
                                            ).then(res => {
                                                let mess: Message[] = []
                                                if(res.data.forEach)
                                                    res.data.forEach((entry: any) => {
                                                        const data =  JSON.parse(entry.response);
                                                        
                                                        mess.push({ 
                                                            id: entry.id,
                                                            author: 'User', 
                                                            input: tryParseJson(entry.input),
                                                            content: tryParseJson(entry.input),
                                                            timestamp: new Date(entry.prompt_timestamp),
                                                            data: undefined,
                                                            show: false,
                                                            agent_reference: entry.agent_reference,
                                                            model: entry.model,
                                                            temperature: entry.temperature,
                                                            prompts: entry.prompts
                                                        }, { 
                                                            id: entry.id,
                                                            author: 'Skynet', 
                                                            content: data.text ? data.text : 'error',
                                                            timestamp: new Date(entry.response_timestamp),
                                                            runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                            data: data,//'error',
                                                            input: tryParseJson(entry.input),
                                                            tags: entry.metadata,
                                                            docsearch: entry.docsearch,
                                                            show: false,
                                                            agent_reference: entry.agent_reference,
                                                            model: entry.model,
                                                            temperature: entry.temperature,
                                                            prompt_tokens: entry.prompt_tokens,
                                                            completion_tokens: entry.completion_tokens,
                                                            signature: entry.signature,
                                                            prompts: entry.prompts,
                                                            public: entry.public
                                                        });
                                                    })
                                                
                                                setMessages(mess);
                                                message.success('successfully signed');
                                            });
                                        }).catch(err => {console.log(err);message.error(err.toString())});
                                    }}
                                    publish={()=>{ 
                                        axios.post(
                                            `${azure_function_url}/NCID?command=signature_request`,
                                            {
                                                document: {
                                                    id: _message.id,
                                                    version: 1,
                                                    type: 'sike_microsignature',

                                                    model: _message.model,
                                                    tags: _message.tags,
                                                    temperature: _message.temperature,
                                                    agent_reference: _message.agent_reference,
                                                    sources:  _message.data ? uniqueObjects(_message.data.sourceDocuments.map((x:any)=> { return {name: x?.metadata?.name, type: x?.metadata?.type, id: x?.metadata?.id }}).flat()) : undefined
                                                },
                                            },
                                            {
                                                maxContentLength: Number.POSITIVE_INFINITY,
                                                headers: {
                                                    'Authorization': parent.bearerToken
                                                }
                                            }
                                        ).then(x => { 
                                            console.log(x)
                                            
                                            axios.post(
                                                `${azure_function_url}/LLMModel?command=publish&context=${parent.context}&thread=${parent.thread}&id=${_message.id}`,
                                                {
                                                    signature: x.data
                                                },
                                                {
                                                    headers: {
                                                        'Authorization': parent.bearerToken
                                                    },
                                                    
                                                }
                                            ).then(res => {
                                                console.log(res)
                                                let mess: Message[] = []
                                                if(res.data.forEach)
                                                    res.data.forEach((entry: any) => {
                                                        const data =  JSON.parse(entry.response);
                                                        
                                                        mess.push({ 
                                                            id: entry.id,
                                                            author: 'User', 
                                                            input: tryParseJson(entry.input),
                                                            content: tryParseJson(entry.input),
                                                            timestamp: new Date(entry.prompt_timestamp),
                                                            data: undefined,
                                                            show: false,
                                                            agent_reference: entry.agent_reference,
                                                            model: entry.model,
                                                            temperature: entry.temperature,
                                                            prompts: entry.prompts
                                                        }, { 
                                                            id: entry.id,
                                                            author: 'Skynet', 
                                                            content: data.text ? data.text : 'error',
                                                            timestamp: new Date(entry.response_timestamp),
                                                            runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                            data: data,//'error',
                                                            input: tryParseJson(entry.input),
                                                            tags: entry.metadata,
                                                            docsearch: entry.docsearch,
                                                            show: false,
                                                            agent_reference: entry.agent_reference,
                                                            model: entry.model,
                                                            temperature: entry.temperature,
                                                            prompt_tokens: entry.prompt_tokens,
                                                            completion_tokens: entry.completion_tokens,
                                                            signature: entry.signature,
                                                            prompts: entry.prompts,
                                                            public: entry.public
                                                        });
                                                    })
                                                
                                                setMessages(mess);
                                                message.success('successfully signed');
                                            });
                                        }).catch(err => {console.log(err);message.error(err.toString())});
                                    }}
                                    openSources={()=>{
                                        _message.show = true;
                                        setOpenMessages(_message);
                                    }}
                                    download={(id: string, name: string, type: string)=>{ 
                                            try{
                                                axios.get(
                                                    `${azure_function_url}/LLMModel?command=files_get&id=${id}`,
                                                    {
                                                        headers: {
                                                        'Authorization': parent.bearerToken
                                                }})
                                                .then(data => {
                                                    const file = new File([Buffer.from(data.data.data,"base64")], data.data.name, {type: 'application/octet-stream'});
                                                    const element = document.createElement("a");
                                                    element.href = URL.createObjectURL(file);
                                                    element.download =  data.data.name;
                                                    document.body.appendChild(element);
                                                    element.click();
                                                })

                                                

                                            }catch(err:any){{console.log(err);message.error(err.toString())}};
                                    }}
                                    references={{
                                        top: messagesTopRef,
                                        end: messagesEndRef,
                                        redo: ref_redo,
                                        microsig: ref_microsig,
                                        publish: ref_publish,
                                        sources: ref_sources,
                                        copy: ref_copy,
                                        messages: ref_messages

                                    }}
                                />
                            </List.Item>     
                        </>
                    )}
                />
                
                <div style={{ 
                    display: 'flex', 
                    
                    flexDirection: 'column', // Added for vertical alignment
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
    
                    width: '100%',
                    boxSizing: 'border-box',
                    backgroundColor: 'white',
                    borderRadius: '15px 15px', 
                    border: '1px solid rgba(0, 0, 0, 0.75)', 

                    position: 'absolute',
                    bottom: '0px',
                }}>
                    <Form 
                        ref={formRef} 
                        style={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'row', 
                            margin: '10px' 
                        }} 
                        initialValues={{prompts:[{prompt:''}]}}
                    >
                        <Button 
                            type="primary" 
                            icon={(isLoadingChat.state ? <LoadingOutlined />:<></>)} 
                            style={{ 
                                color: 'rgba(0, 0, 0, 0.75)',
                                backgroundColor: 'transparent', 
                                borderColor: 'transparent', 
                                borderRadius: '0 0 0 0',
                                alignSelf: 'flex-end',
                                marginBottom: '0px',
                                boxShadow:'none'
                            }}
                        />
                        
                        <Item style={{ flexGrow: 1, marginBottom: 0, marginRight: '-1px' }}>
                            <div ref={ref_input} style={{ width: '100%' }}>
                                <div ref={messageInputRef} ></div>
                                <Form.List name="prompts">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <div key={key} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                    { agent === 'beta' && (key > 0 ? (<MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: '8px', marginRight: '10px', marginBottom: '12px', color: 'red' }} />) : (<PlusCircleOutlined onClick={() => add()} style={{ marginLeft: '8px', marginRight: '10px', marginBottom: fields.length === 1 ? undefined : '12px' }} />))}
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'prompt']}
                                                        style={{ flex: 1, marginBottom: fields.length === 1 ? 0 : undefined, maxHeight: '400px', overflow: 'auto' }}
                                                    >
                                                        <Idea
                                                            placeholder={ "How can I help you?" }
                                                            disabled={isLoadingChat.state}
                                                            noborder
                                                            handleKeyDown={handleKeyDown}
                                                            agent={agent}
                                                            tags={tags}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </div>
                        </Item>
                        
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Space.Compact 
                                style={{ 
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexGrow: 1,
                                    marginBottom: 0,
                                    marginRight: '-1px',
                                }}
                            >
                                <div ref={ref_agent}>
                                { !parent.isMobile && parent.prompt === undefined && (<Item name={'agent'} noStyle>
                                    <Select 
                                        style={{ 
                                            width: '150px'
                                        }} 
                                        value={agent}
                                        onChange={(value) => { setAgent(value) }}  
                                        placeholder="Select persona">
                                        { Object.keys(prompts).map((key) => <Option key={key} value={key}>{prompts[key].label}</Option> )}
                                    </Select>                                
                                </Item>)}
                                </div>
                                { parent.prompt === undefined && tagTree && tagTree.length > 0 && (
                                <div ref={ref_tags}>
                                <Item name={'tags'} noStyle>
                                    <TreeSelect
                                        style={{ 
                                            // width: '200px',
                                            minWidth: '50px',
                                        }} 
                                        dropdownStyle={{ 
                                            // minWidth: '450px', // Ensure that the dropdown is at least as wide as the input
                                            // maxWidth: '50%', // Example to ensure it doesn't exceed the viewport width, adjust as necessary
                                            minWidth: parent.isMobile ? '300px' : '450px',
                                            maxWidth: '50%',
                                        }}
                                        // placeholder={'Select tags...'}
                                        placeholder={<ApartmentOutlined />}
                                        multiple={true}
                                        onChange={e => setTags(e)} 
                                        treeData={tagTree}
                                    />  
                                </Item>
                                </div>
                                )}
                                
                            </Space.Compact>
                            <Button
                                ref={ref_send}
                                type="primary" 
                                icon={<SendOutlined />} 
                                onClick={handleMessageSend}
                                style={{ 
                                    color: 'rgba(0, 0, 0, 0.75)',
                                    backgroundColor: 'transparent'
                                }}
                            />
                        </div>
                    </Form>
                </div>
                
                <FloatButton.Group
                    type="default"
                    style={{ 
                        right: 7, 
                        bottom: parent.isMobile ? 90: 25 
                    }}
                >
                    <Popconfirm
                        title="Delete All Messages"
                        description="Would you like to delete all the messages?"
                        onConfirm={() => { 
                            axios.get(
                                `${azure_function_url}/LLMModel?command=history_delete_all&context=${parent.context}&thread=${parent.thread}`, 
                                {
                                    headers: {
                                    'Authorization': parent.bearerToken
                                    }
                                }).then(res => {
                                    let mess: Message[] = []
                                    if(res.data.forEach)
                                        res.data.forEach((entry: any) => {
                                            const data =  JSON.parse(entry.response);
                                            mess.push({ 
                                                id: entry.id,
                                                author: 'User', 
                                                input: tryParseJson(entry.input),
                                                content: tryParseJson(entry.input),
                                                timestamp: new Date(entry.prompt_timestamp),
                                                data: undefined,
                                                show: false,
                                                agent_reference: entry.agent_reference,
                                                model: entry.model,
                                                temperature: entry.temperature,
                                                prompts: entry.prompts
                                            }, { 
                                                id: entry.id,
                                                author: 'Skynet', 
                                                content: data.text ? data.text : 'error',
                                                timestamp: new Date(entry.response_timestamp),
                                                runningTime: new Date(entry.response_timestamp).getTime() - new Date(entry.prompt_timestamp).getTime(),
                                                data: data,//'error',
                                                input: tryParseJson(entry.input),
                                                tags: entry.metadata,
                                                docsearch: entry.docsearch,
                                                show: false,
                                                agent_reference: entry.agent_reference,
                                                model: entry.model,
                                                temperature: entry.temperature,
                                                prompt_tokens: entry.prompt_tokens,
                                                completion_tokens: entry.completion_tokens,
                                                signature: entry.signature,
                                                prompts: entry.prompts,
                                                public: entry.public
                                            });
                                        })
                                    
                                    message.success('Messages deleted');
                                    setMessages(mess);
                            }).catch(err => {console.log(err);message.error(err.toString())});
                            }
                        } 
                        onCancel={() => { }}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <FloatButton tooltip={<div>Clear Chat History</div>} icon={<DeleteOutlined />} onClick={()=> {}} />
                    </Popconfirm>     
                    { parent.permission !== 'OWNER' && parent.context !== parent.user.id  && (
                    <Popconfirm
                        title="Exist Knodule"
                        description="Would you like to exist the knodule?"
                        onConfirm={() => { 
                            axios.post(
                                `${azure_function_url}/NCID?command=employees_decline`,
                                {
                                    entityid: parent.context,
                                },
                                {
                                    maxContentLength: Number.POSITIVE_INFINITY,
                                    headers: {
                                        'Authorization': parent.bearerToken
                                    }
                                }
                            ).then(x => {
                                window.location.reload();
                            });
                        }} 
                        onCancel={() => { }}
                        okText="Exist"
                        cancelText="Cancel"
                    >
                        <FloatButton tooltip={<div>Exist Knodule</div>} icon={<LogoutOutlined />} onClick={()=> {}} />
                    </Popconfirm>
                    )}    
                    <FloatButton tooltip={<div>Scroll Up</div>} icon={<UpOutlined />} onClick={()=> {scrollToTop();}} />
                    <FloatButton tooltip={<div>Scroll Down</div>} icon={<DownOutlined />} onClick={()=> {scrollToBottom();}} />
                    {!parent.isMobile && <FloatButton tooltip={<div>Tour</div>} icon={<InfoCircleOutlined />} onClick={()=> {setOpen(true);}} />}
                </FloatButton.Group>
            </div>
          
            <Modal okType={'default'} okText={'Close'} cancelButtonProps={{ style: { display: 'none' } }} title="View Sources" width={1000} onCancel={() => setOpenMessages(undefined)} onOk={() => setOpenMessages(undefined)}  closable={true} open={openMessage !== undefined}>
                <Card bordered={true} style={{ width: '100%' }}>
                    <div dangerouslySetInnerHTML={{ __html: renderMarkdown(openMessage ? openMessage.content.toString() : '') }} />

                    {openMessage?.data.sourceDocuments.length > 0 ?
                    <>
                    <Divider orientation="left" plain>Sources</Divider>
                    <List
                        style={{ 
                            width: '100%', 
                            margin: '0 auto'
                        }}
                        itemLayout="vertical"
                        dataSource={openMessage?.data.sourceDocuments.map((x:any,i:number)=>{return {...x, key:i}})}
                        renderItem={(_message:any) => 
                            <List.Item>
                                
                                <div style={{ paddingLeft: '40px', paddingRight: '40px' }} dangerouslySetInnerHTML={{ __html: renderMarkdown(_message.pageContent) }} />
                                <br></br>
                                <br></br>
                                <JsonView collapsed={1} src={_message.metadata} />    
                            </List.Item>
                        }
                    />
                    </>
                    :<></>
                    }
                    
                </Card>
            </Modal>
        </>
    );
}