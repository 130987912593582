// App.tsx

import React, { useState, useEffect } from 'react';

import { List, Menu, Popconfirm, Radio, Card, FloatButton, Input, Button, Upload, Table, DatePicker, Space, Tabs, message } from 'antd';
import type { MenuProps } from 'antd';

import { MenuFoldOutlined, MenuUnfoldOutlined, AuditOutlined, SlidersOutlined, DotChartOutlined, CloseOutlined, CheckOutlined, EllipsisOutlined, PlusCircleOutlined, UploadOutlined, MessageOutlined, LoadingOutlined, CloudDownloadOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, DatabaseOutlined } from '@ant-design/icons';

import { isMobileBrowser } from '../../utils/utils';

import axios from 'axios';

import { PromptFlow } from '../sike/PromptFlow';


type MenuItem = Required<MenuProps>['items'][number];

const documentTypes: Record<number, string> = {
    500: 'Conversational',
    1000: 'Technical',
    4000: 'Legal',
    8191: 'Max'
}

const InputLabel: React.FC<any>  = (parent: any) => {
    const [editValue, setEditValue] = useState(parent.label);
    
    return (
        <div 
            style={{ 
                display: 'flex', 
                alignItems: 'center', 
                width:'calc(100%  - 50px)',
                borderRadius: '7px',
                height: '70px',
                paddingLeft: parent.selectedThread === parent.keyVal ? '9px' : '12px', // adjust padding if needed when selected
                borderLeft: parent.selectedThread === parent.keyVal ? '3px solid #d9d9d9' : undefined, // add a left border to indicate selection
                backgroundColor: parent.selectedThread === parent.keyVal ? '#f5f5f5' : undefined, // light blue background for selection, you can choose any color you prefer
            }}
        >
            <a 
                style={{ flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft:'10px' }}
                onClick={(e)=>{ parent.setThread(parent.keyVal)}}
            >
                {editValue}
            </a>
        </div>
    );
};

export const Teams: React.FC<any> = (parent) => {
    const [thread, setThread] = useState<string>('-');

    const [context, setContext] = useState(parent.user.id);
    const [flows, setFlows] = useState<Record<string,any>>(); // Default active tab key

    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const [isMobile, _] = useState<boolean>(isMobileBrowser());
    const [openKeys, setOpenKeys] = useState(['1']);

    const [sikeUpdateCounter, setSikeUpdateCounter] = useState(0); // Default active tab key

    const [openMenu, setOpenMenu] = useState(true);

    const getItem = (
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group'
    ): MenuItem => {
        
        return {
            key,
            icon,
            children,
            label: <InputLabel bearerToken={parent.bearerToken} context={context} label={label} keyVal={key} selectedThread={thread} setThread={setThread} />,
            type
        } as MenuItem;
    };
    
    const azure_function_url = process.env.AZURE_FUNCTION_URL;

    const loadFlowList = () => {
        axios.get(
            `${azure_function_url}/LLMModel?command=flow_list`, 
            {
                headers: {
                  'Authorization': parent.bearerToken
                }
            }).then(res => {

                const records = res.data.reduce((acc: Record<string, any>, curr: any) => {
                    const key = String(curr.id); // Ensuring the key is a string
                    acc[key] = curr;
                    return acc;
                }, {});

                setFlows(records);

                const groups = res.data.reduce((acc: Record<string, any>, curr: any) => {
                    const key = String(curr['context']); // Ensuring the key is a string
                    if (!acc[key]) {
                      acc[key] = { name: curr['context_name'], data: []};
                    }
                    acc[key].data.push(curr);
                    return acc;
                }, {});

                const it = Object.keys(groups).map(key => {
                    const subs = groups[key].data.map((x:any) => getItem(x.name, x.id));
                    return getItem(groups[key].name, key, undefined, subs);
                })

                setMenuItems(it);

            }).catch(err => {console.log(err);message.error(err.toString())});
    }
  
    useEffect(() => {
        loadFlowList();
    }, [context, thread]);

    return (
        <>
            
            <div style={{display: 'flex', justifyContent: 'center', height: 'calc(100vh - 65px)'}}>
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                        if (latestOpenKey){
                            setOpenKeys(keys);
                        } else {
                            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
                        }
                    }}
                    style={{ 
                        display: openMenu ? undefined:  'none',
                        width: 400, 
                        fontSize: '25px',
                        fontWeight: 100,
                        position: 'sticky',
                        top: '100px',
                        overflowY: 'auto'
                    }}
                    items={menuItems}
                />
                <Card 
                    bordered={false} 
                    className='custom-card'
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto', // Prevents overflow outside the Card
                        marginRight: '55px',
                        marginLeft: openMenu ? undefined:  '55px'
                    }}
                >
                    <PromptFlow bearerToken={parent.bearerToken} user={parent.user} updateList={loadFlowList} context={flows && thread ? flows[thread]?.context : undefined} thread={'_flows_'} permission={parent.permission} updateCounter={sikeUpdateCounter} prompt={flows && thread ? flows[thread] : undefined} />
                </Card>
                <FloatButton.Group
                    type="default"
                    style={{ left: 7, bottom: 22 }}
                >
                    <FloatButton tooltip={<div>Menu</div>} icon={openMenu ? <MenuFoldOutlined  /> : <MenuUnfoldOutlined />} onClick={()=> {setOpenMenu(!openMenu);}} />
                </FloatButton.Group>
            </div>
        </>
    );
}