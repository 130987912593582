import React, { useState } from 'react';
import { Form, Input, Col, Row, Button, Divider, Typography } from 'antd';

import { Tokens, Deals, Files } from '@notcentralised/notvault-sdk';

const { Paragraph } = Typography;

export const Approve: React.FC<any> = (data) => {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);

    const [tokens] = useState<Tokens>(new Tokens(data.vault));
    const [files] = useState<Files>(new Files(data.vault));
    const [deals] = useState<Deals>(new Deals(data.vault, tokens, files));

    const onSend = async (values: any) => {
        setLoading(true);

        await deals.approve(values.key, values.value);

        form.resetFields();
        data.closeModal();
        setLoading(false);
    };

    return (
        <div>
            <Form
                name="approve"
                form={form}
                onFinish={onSend}
                labelCol={{ span: 3 }}
            >
                <Divider orientation="left"></Divider>
                <Paragraph style={{textAlign:'left'}}>
                Set an approval value.
                </Paragraph>
                <br></br>
                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item 
                        label="Key" 
                        name="key"
                        rules={[{ required: true, message: 'Please input request' }]}
                    >
                        <Input/>
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item 
                        label="Value" 
                        name="value"
                        rules={[{ required: true, message: 'Please input request' }]}
                    >
                        <Input/>
                    </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left"></Divider>
                <Form.Item>
                    <Button 
                        style={{
                            position: "absolute",
                            width: "120px",
                            left: 0,
                            transform: "translateY(-50%)"
                        }} 
                        onClick={() => { form.resetFields(); setLoading(false); data.closeModal(); }}
                        disabled={false}
                    >
                        Cancel
                    </Button>

                    <Button 
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        style={{
                            position: "absolute",
                            right: 0,
                            width: "120px",
                            transform: "translateY(-50%)"
                        }} 
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}